<template>
	<BaseDialog v-model="dialogStore.dialogs.welcome.active" name="welcome">
		<template v-slot:text>
			<h2 class="mb-4 text-wrap d-flex align-center justify-center text-center">
				{{ $t('welcome.title') }}
			</h2>

			<p class="mb-5 text-medium-emphasis text-body-2 px-lg-10">
				{{ $t('welcome.subtitle') }}
			</p>

			<h3 class="mb-3">{{ $t('welcome.howItWorks') }}</h3>

			<v-divider class="mb-4"></v-divider>

			<div class="mb-4 text-body-2 d-flex align-center">
				<v-icon
					:icon="mdiNumeric1"
					size="30"
					class="me-4 ms-n2 text-amber-darken-3"
				/>

				<i18n-t
					keypath="welcome.step1"
					tag="div"
					class="text-start"
					scope="global"
				>
					<template v-slot:create>
						<strong class="text-green-darken-3">
							{{ $t('welcome.create') }}
						</strong>
					</template>
				</i18n-t>
			</div>

			<v-divider class="mb-4"></v-divider>

			<div class="mb-4 text-body-2 d-flex align-center">
				<v-icon
					:icon="mdiNumeric2"
					size="30"
					class="me-4 ms-n2 text-amber-darken-3"
				/>

				<i18n-t
					keypath="welcome.step2"
					tag="div"
					class="text-start"
					scope="global"
				>
					<template v-slot:energy>
						<span class="text-yellow-darken-3 ms-n1 text-no-wrap">
							<v-icon
								:icon="mdiLightningBolt"
								size="16"
								color="yellow-darken-3"
							/>
							<span>{{ $t('welcome.energy', 0) }}</span>
						</span>
					</template>
					<template v-slot:energy2>
						<span class="text-yellow-darken-3 ms-n1 text-no-wrap">
							<v-icon
								:icon="mdiLightningBolt"
								size="16"
								color="yellow-darken-3"
							/>
							<span>{{ $t('welcome.energy', 1) }}</span>
						</span>
					</template>
				</i18n-t>
			</div>

			<v-divider class="mb-4"></v-divider>

			<div class="mb-4 text-body-2 d-flex align-center">
				<v-icon
					:icon="mdiNumeric3"
					size="30"
					class="me-4 ms-n2 text-amber-darken-3"
				/>
				<i18n-t
					keypath="welcome.step3"
					tag="div"
					class="text-start"
					scope="global"
				>
					<template v-slot:energy>
						<span class="text-yellow-darken-3 ms-n1 text-no-wrap">
							<v-icon
								:icon="mdiLightningBolt"
								size="16"
								color="yellow-darken-3"
							/>
							<span>{{ $t('welcome.energy', 2) }}</span>
						</span>
					</template>
				</i18n-t>
			</div>

			<v-divider class="mb-4"></v-divider>

			<div class="mb-4 text-body-2 d-flex align-center">
				<v-icon
					:icon="mdiNumeric4"
					size="30"
					class="me-4 ms-n2 text-amber-darken-3"
				/>
				<i18n-t
					keypath="welcome.step4"
					tag="div"
					class="text-start"
					scope="global"
				>
					<template v-slot:energy>
						<span class="text-yellow-darken-3 ms-n1 text-no-wrap">
							<v-icon
								:icon="mdiLightningBolt"
								size="16"
								color="yellow-darken-3"
							/>
							<span>{{ $t('welcome.energy', 0) }}</span>
						</span>
					</template>
				</i18n-t>
			</div>

			<v-divider class="mb-4"></v-divider>
			<h4 class="text-center mt-5">
				{{ $t('welcome.end') }}
			</h4>
		</template>
		<template v-slot:actions>
			<v-btn
				variant="elevated"
				class="px-10 rounded-lg"
				@click="dialogStore.closeDialog('welcome')"
			>
				<span>{{ $t('close') }}</span>
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script lang="ts" setup>
import { watch } from 'vue'
import {
	mdiLightningBolt,
	mdiNumeric1,
	mdiNumeric2,
	mdiNumeric3,
	mdiNumeric4,
} from '@mdi/js'
import { useDialogStore } from '../../stores/dialogStore'
import BaseDialog from '../BaseDialog.vue'

const dialogStore = useDialogStore()

watch(
	() => dialogStore.dialogs.welcome.active,
	(active) => {
		if (active === false) {
		}
	}
)
</script>

<style scoped></style>
