<template>
	<v-list>
		<v-list-item v-if="user">
			<div
				class="d-flex justify-center align-center text-subtitle-2 text-blue-grey-darken-1"
			>
				{{ user.name }}
			</div>
			<div
				v-if="$vuetify.display.mobile"
				class="mt-2 d-flex justify-center align-center text-subtitle-2 text-yellow-darken-3"
			>
				{{ user.coins }}
				<img class="ms-2" alt="Coins" src="/images/coin.png" height="15" />
			</div>
		</v-list-item>
		<v-list-item v-if="$vuetify.display.mobile && user">
			<v-btn
				width="100%"
				variant="text"
				size="small"
				color="yellow-darken-4"
				@click="dialogStore.openDialog('buyCoins')"
			>
				<span class="text-yellow-darken-4">
					{{ $t('premium.buyCoins') }}
				</span>
			</v-btn>
		</v-list-item>
		<v-list-item v-if="user">
			<v-btn
				width="100%"
				variant="text"
				size="small"
				@click="dialogStore.openDialog('settings')"
			>
				{{ $t('userMenu.settings') }}
			</v-btn>
		</v-list-item>
		<v-list-item>
			<v-btn
				width="100%"
				variant="text"
				size="small"
				@click="dialogStore.openDialog('feedback')"
			>
				{{ $t('userMenu.feedback') }}
			</v-btn>
		</v-list-item>
		<v-list-item>
			<v-btn
				width="100%"
				variant="text"
				size="small"
				@click="dialogStore.openDialog('welcome')"
			>
				{{ $t('userMenu.help') }}
			</v-btn>
		</v-list-item>
		<v-list-item v-if="user">
			<v-btn
				width="100%"
				variant="text"
				size="small"
				@click="dialogStore.openDialog('quests')"
			>
				{{ $t('userMenu.quests') }}
			</v-btn>
		</v-list-item>
		<v-list-item v-if="user">
			<v-btn
				width="100%"
				variant="text"
				size="small"
				@click="dialogStore.openDialog('referral')"
			>
				{{ $t('userMenu.referral') }}
			</v-btn>
		</v-list-item>
		<v-list-item v-if="!webApp?.initData">
			<v-btn width="100%" variant="text" size="small" to="/logout">
				{{ $t('userMenu.logout') }}
			</v-btn>
		</v-list-item>
	</v-list>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useWebApp } from '../plugins/webApp'
import { useUserStore } from '../stores/userStore'
import { useDialogStore } from '../stores/dialogStore'

const user = computed(() => useUserStore().user)
const webApp = useWebApp()
const dialogStore = useDialogStore()
</script>

<style scoped></style>
