<template>
	<BaseDialog
		v-model="dialogStore.dialogs.questReward.active"
		name="questReward"
	>
		<template v-slot:text>
			<v-icon :icon="mdiCheckBold" size="60" color="green-darken-3"></v-icon>
			<h2 class="mt-4">{{ $t('quests.congratulations') }}</h2>
			<div class="mt-4 text-medium-emphasis text-body-2">
				{{
					$t(
						`quests.${dialogStore.dialogs.questReward.questType}.rewardMessage`
					)
				}}
			</div>

			<h4 class="mt-5">{{ $t('quests.youReceived') }}:</h4>

			<div class="mt-2 d-flex align-center justify-center text-h6">
				<span class="font-weight-bold">
					{{ dialogStore.dialogs.questReward.coins }}
				</span>
				<img class="ms-2" alt="coins" src="/images/coin.png" height="15" />
				<span class="ms-1 text-capitalize text-yellow-darken-3">{{
					$t('quests.coins')
				}}</span>
			</div>
		</template>
		<template v-slot:actions>
			<v-btn
				variant="elevated"
				color="green-darken-3"
				class="px-8 rounded-lg"
				:autofocus="false"
				@click="dialogStore.closeDialog('questReward')"
			>
				<span>{{ $t('ok') }}</span>
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script setup lang="ts">
import { mdiCheckBold } from '@mdi/js'
import { useDialogStore } from '../../stores/dialogStore'
import BaseDialog from '../BaseDialog.vue'

const dialogStore = useDialogStore()
</script>
