<template>
	<div class="pa-4 w-100 text-center">
		<div class="text-caption">
			© Pheely 2024
			<span class="ma-2">|</span>
			<RouterLink
				v-for="availableLocale in $i18n.availableLocales"
				:to="`/${availableLocale}`"
				class="text-caption ma-1"
				:hreflang="availableLocale"
				rel="alternate"
			>
				{{ $t(`locales.${availableLocale}`) }}
			</RouterLink>
		</div>
		<div class="text-center mt-2 text-caption text-grey-darken-1">
			<RouterLink :to="{ path: localePath('/privacy') }">
				{{ $t('privacyPolicy') }}
			</RouterLink>
			|
			<RouterLink :to="{ path: localePath('/terms') }">
				{{ $t('termsOfUse') }}
			</RouterLink>
			|
			<RouterLink :to="{ path: localePath('/offer') }">
				{{ $t('offerAgreement') }}
			</RouterLink>
			|
			<span class="cursor-pointer" @click="dialogStore.openDialog('feedback')">
				{{ $t('feedback.title') }}
			</span>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useDialogStore } from '../stores/dialogStore'
import { localePath } from '../composables/useLanguage'

const dialogStore = useDialogStore()
</script>

<style scoped></style>
