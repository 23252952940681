<template>
	<template v-for="message in flashMessageStore.messages" :key="message.id">
		<v-snackbar
			v-model="message.show"
			location="top"
			color="transparent"
			elevation="1"
			:close-on-content-click="true"
			class="transparent-snackbar d-flex px-5 mt-7 mt-lg-1"
			:timeout="message.timeout"
			:min-height="0"
			:min-width="280"
			:height="33"
			:style="{
				top: message.show ? `${getMessageIndex(message.id) * 37}px` : 0,
			}"
			transition="slide-y-transition"
			rounded="xl"
			@update:model-value="
				(val) => !val && flashMessageStore.hideMessage(message.id)
			"
		>
			<div
				class="d-flex align-center justify-center text-subtitle-2 px-10 py-2"
				:class="message.bgColor"
			>
				<component
					:is="messageComponents[message.type]"
					v-bind="message.data || {}"
				/>
			</div>
		</v-snackbar>
	</template>
</template>

<script setup lang="ts">
import {
	messageComponents,
	useFlashMessageStore,
} from '../stores/flashMessageStore'

const flashMessageStore = useFlashMessageStore()

function getMessageIndex(id: number): number {
	const visibleMessages = flashMessageStore.messages.filter((m) => m.show)
	return visibleMessages.findIndex((m) => m.id === id)
}
</script>

<style>
.transparent-snackbar .v-snackbar__content {
	padding: 0 !important;
	cursor: pointer;
}

.v-snackbar {
	transition: top 0.5s cubic-bezier(0.4, 0, 0.2, 1),
		opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) !important;
}
</style>
