<template>
	<BaseDialog
		v-model="dialogStore.dialogs.paymentStatus.active"
		name="paymentStatus"
	>
		<template v-slot:text>
			<div v-if="!success" class="mb-5">
				<v-icon :icon="mdiCancel" size="55" color="red-darken-2" class="" />
				<h3 class="my-2">
					{{ $t('payment.canceled') }}
				</h3>
			</div>
			<div v-else-if="success" class="mb-5">
				<v-icon
					:icon="mdiCheckBold"
					size="55"
					color="green-darken-2"
					class=""
				/>
				<h2 class="my-2">{{ $t('payment.success') }}</h2>
				<span
					v-if="coins"
					class="my-3 text-center text-medium-emphasis d-flex align-center justify-center"
				>
					{{ $t('payment.youReceived') }}
					<span class="text-yellow-darken-3 d-flex align-center ms-1">
						{{ coins }}
						<img class="mx-1" alt="coins" src="/images/coin.png" height="15" />
					</span>
				</span>
			</div>
			<a
				ref="paymentLink"
				class="d-none"
				target="_blank"
				rel="noopener noreferrer"
			></a>
		</template>
		<template v-slot:actions>
			<v-btn
				variant="elevated"
				class="px-10 rounded-lg"
				@click="dialogStore.closeDialog('paymentStatus')"
			>
				<span>{{ $t('close') }}</span>
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { mdiCancel, mdiCheckBold } from '@mdi/js'
import { useDialogStore } from '../../stores/dialogStore'
import BaseDialog from '../BaseDialog.vue'

const dialogStore = useDialogStore()
const success = computed(() => dialogStore.dialogs.paymentStatus.success)
const coins = computed(() => dialogStore.dialogs.paymentStatus.coins)
</script>

<style scoped></style>
