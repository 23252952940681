<template>
	<BaseDialog v-model="dialogStore.dialogs.quests.active" name="quests">
		<template v-slot:text>
			<div v-if="questStore.isLoading" class="d-flex justify-center">
				<v-progress-circular
					indeterminate
					color="orange-darken-1"
				></v-progress-circular>
			</div>
			<div
				v-else-if="questStore.quests"
				class="d-flex flex-column justify-center align-center"
			>
				<v-icon
					:icon="mdiClockCheckOutline"
					size="60"
					color="green-darken-2"
				></v-icon>

				<h3 class="mt-4">{{ $t('quests.title') }}</h3>

				<!-- Daily Votes Quest -->
				<div v-if="questStore.quests.dailyVotes" class="w-100">
					<v-divider class="my-4"></v-divider>

					<div class="text-subtitle-2 mb-3">
						{{
							$t('quests.dailyVotes.description', {
								votes: questStore.quests.dailyVotes.requiredVotes,
							})
						}}
					</div>

					<div class="mx-lg-10">
						<v-progress-linear
							:model-value="
								(questStore.quests.dailyVotes.currentVotes /
									questStore.quests.dailyVotes.requiredVotes) *
								100
							"
							:color="
								questStore.quests.dailyVotes.completed ? 'success' : 'primary'
							"
							height="12"
							rounded
							class="px-lg-10"
						></v-progress-linear>
					</div>

					<div class="mt-1 text-center text-medium-emphasis text-caption">
						{{ questStore.quests.dailyVotes.currentVotes }}/{{
							questStore.quests.dailyVotes.requiredVotes
						}}
					</div>

					<div
						v-if="questStore.quests.dailyVotes.completed"
						class="mt-2 d-flex align-center justify-center text-yellow-darken-3 text-subtitle-2"
					>
						{{
							$t('quests.completed', {
								coins: questStore.quests.dailyVotes.reward,
							})
						}}
					</div>
					<div v-else>
						<div class="me-2 mt-2 text-caption text-green-darken-3">
							{{ $t('quests.reward') }}:
						</div>
						<h4 class="d-flex align-center justify-center">
							<span class="font-weight-bold">
								{{ questStore.quests.dailyVotes.reward }}
							</span>
							<img
								class="ms-2"
								alt="coins"
								src="/images/coin.png"
								height="15"
							/>
							<span class="ms-1 text-capitalize text-yellow-darken-3">
								{{ $t('quests.coins') }}
							</span>
						</h4>
					</div>
				</div>

				<!-- Login Streak Quest -->
				<div v-if="questStore.quests.loginStreak" class="w-100">
					<v-divider class="my-4"></v-divider>

					<div class="text-subtitle-2 mb-3">
						{{
							$t('quests.loginStreak.description', {
								days: questStore.quests.loginStreak.requiredDays,
							})
						}}
					</div>
					<div class="mx-lg-10">
						<v-progress-linear
							:model-value="
								(questStore.quests.loginStreak.currentStreak /
									questStore.quests.loginStreak.requiredDays) *
								100
							"
							:color="
								questStore.quests.loginStreak.completed ? 'success' : 'primary'
							"
							height="12"
							rounded
							class="px-lg-10"
						></v-progress-linear>
					</div>
					<div class="mt-1 text-center text-medium-emphasis text-caption">
						{{ questStore.quests.loginStreak.currentStreak }}/{{
							questStore.quests.loginStreak.requiredDays
						}}
					</div>

					<div
						v-if="questStore.quests.loginStreak.completed"
						class="mt-2 d-flex align-center justify-center text-yellow-darken-3 text-subtitle-2"
					>
						{{
							$t('quests.completed', {
								coins: questStore.quests.loginStreak.reward,
							})
						}}
					</div>
					<div v-else>
						<div class="me-2 mt-2 text-caption text-green-darken-3">
							{{ $t('quests.reward') }}:
						</div>
						<h4 class="d-flex align-center justify-center">
							<span class="font-weight-bold">{{
								questStore.quests.loginStreak.reward
							}}</span>
							<img
								class="ms-2"
								alt="coins"
								src="/images/coin.png"
								height="15"
							/>
							<span class="ms-1 text-capitalize text-yellow-darken-3">
								{{ $t('quests.coins') }}
							</span>
						</h4>
					</div>

					<v-divider class="my-4"></v-divider>
				</div>
			</div>
		</template>
		<template v-slot:actions>
			<v-btn
				variant="elevated"
				class="px-8 rounded-lg"
				:autofocus="false"
				@click="dialogStore.closeDialog('quests')"
			>
				<span>{{ $t('close') }}</span>
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useDialogStore } from '../../stores/dialogStore'
import { useQuestStore } from '../../stores/questStore'
import BaseDialog from '../BaseDialog.vue'
import { mdiClockCheckOutline } from '@mdi/js'

const dialogStore = useDialogStore()
const questStore = useQuestStore()

watch(
	() => dialogStore.dialogs.quests.active,
	async (newValue) => {
		if (newValue) {
			await questStore.initQuests()
		}
	}
)
</script>

<style scoped></style>
