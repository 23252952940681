<template>
	<BaseDialog v-model="dialogStore.dialogs.referral.active" name="referral">
		<template v-slot:text>
			<v-alert
				v-if="error"
				color="red-darken-3"
				class="mb-4 px-4 rounded-lg"
				variant="tonal"
			>
				{{ error }}
			</v-alert>
			<div
				v-if="!referralData"
				class="h-100 w-100 d-flex justify-center align-center"
			>
				<v-progress-circular indeterminate color="orange-darken-1" />
			</div>
			<div v-else-if="showRewardSuccess" class="text-center">
				<v-icon :icon="mdiCheckBold" size="60" color="green-darken-3" />
				<h2 class="mt-4">{{ $t('referral.rewardClaimedSuccess') }}</h2>
				<h4 class="mt-5">{{ $t('referral.youReceived') }}:</h4>

				<div class="mt-2 text-body-1 d-flex align-center justify-center">
					<h3 class="font-weight-bold">{{ referralData.coinsReward }}</h3>
					<img class="ms-2" alt="coins" src="/images/coin.png" height="15" />
					<h3 class="ms-1 text-capitalize text-yellow-darken-3">
						{{ $t('referral.coins') }}
					</h3>
				</div>
			</div>
			<div v-else class="text-center">
				<template v-if="referralData.rewardClaimed">
					<h3 class="mb-4">{{ $t('referral.alreadyClaimed') }}</h3>
					<div class="text-medium-emphasis">
						{{ $t('referral.alreadyClaimedDescription') }}
					</div>
				</template>

				<template
					v-else-if="
						referralData.registrationsCount >=
						referralData.requiredRegistrations
					"
				>
					<h2 class="mb-4">{{ $t('referral.readyToClaimTitle') }}</h2>
					<h4 class="mb-4">{{ $t('referral.readyToClaim') }}</h4>
					<h2
						class="mb-6 d-flex justify-center align-center text-yellow-darken-3"
					>
						{{ referralData.coinsReward }}
						<img class="ms-2" alt="coins" src="/images/coin.png" height="20" />
					</h2>
					<v-btn
						color="green-darken-3"
						:loading="claimLoading"
						class="px-10 rounded-lg"
						@click="claimReward"
					>
						{{ $t('referral.claimReward') }}
					</v-btn>
				</template>

				<template v-else>
					<h3 class="mb-4">
						{{ $t('referral.description') }}
					</h3>

					<h2
						class="mb-6 d-flex justify-center align-center text-yellow-darken-3"
					>
						{{ referralData.coinsReward }}
						<img class="ms-2" alt="coins" src="/images/coin.png" height="20" />
					</h2>

					<v-text-field
						id="referral-link"
						:model-value="referralLink"
						:label="$t('referral.yourLink')"
						readonly
						:hide-details="true"
						variant="outlined"
						class="mt-6 mb-1"
						rounded="lg"
						@click="copyLink"
					/>

					<div class="mb-6 text-caption text-medium-emphasis">
						{{ $t('referral.clickToCopy') }}
					</div>

					<div class="d-flex justify-space-between align-center mb-2">
						<span>{{ $t('referral.progress') }}</span>
						<span class="text-yellow-darken-3">
							{{ referralData.registrationsCount }} /
							{{ referralData.requiredRegistrations }}
						</span>
					</div>

					<v-progress-linear
						:model-value="
							(referralData.registrationsCount /
								referralData.requiredRegistrations) *
							100
						"
						color="yellow-darken-3"
						height="8"
						rounded
					/>
				</template>
			</div>
		</template>

		<template v-slot:actions>
			<v-btn
				variant="elevated"
				:color="showRewardSuccess ? 'green-darken-3' : undefined"
				class="px-10 rounded-lg"
				@click="dialogStore.dialogs.referral.active = false"
			>
				{{ $t(showRewardSuccess ? 'ok' : 'close') }}
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useUserStore } from '../../stores/userStore'
import { useFlashMessageStore } from '../../stores/flashMessageStore'
import { useDialogStore } from '../../stores/dialogStore'
import { useApi } from '../../composables/useApi'
import { useI18n } from 'vue-i18n'
import BaseDialog from '../BaseDialog.vue'
import { config } from '../../config'
import { useWebApp } from '../../plugins/webApp'
import { mdiCheckBold } from '@mdi/js'

const error = ref('')
const userStore = useUserStore()
const flashMessageStore = useFlashMessageStore()
const dialogStore = useDialogStore()
const { t } = useI18n()

const claimLoading = ref(false)
const referralData = ref<ReferralData | null>(null)
const showRewardSuccess = ref(false)

const referralLink = computed(() => {
	if (!referralData.value?.code) return ''

	const webApp = useWebApp()
	if (webApp.initData) {
		return `https://t.me/${config.botName}?startapp=ref_${referralData.value.code}`
	}

	const domain = import.meta.env.VITE_APP_URL
	return `${domain}/?ref=${referralData.value.code}`
})

const copyLink = () => {
	navigator.clipboard.writeText(referralLink.value)
	flashMessageStore.showMessage('info', 3000, 'bg-message-success-bar', {
		message: t('referral.linkCopied'),
	})
}

const claimReward = async () => {
	error.value = ''
	claimLoading.value = true
	try {
		const response = await useApi.referral.claimReward()
		if (response.success) {
			userStore.user.coins = response.coins
			if (referralData.value) {
				referralData.value.rewardClaimed = true
			}
			showRewardSuccess.value = true
		} else if (response.error) {
			error.value = response.error
		}
	} catch (error: any) {
		error.value = t('error')
	} finally {
		claimLoading.value = false
	}
}

watch(
	() => dialogStore.dialogs.referral.active,
	async (newVal) => {
		if (newVal === true) {
			try {
				error.value = ''
				referralData.value = null
				const response = await useApi.referral.info()
				referralData.value = {
					code: response.code,
					registrationsCount: response.registrationsCount,
					requiredRegistrations: response.requiredRegistrations,
					coinsReward: response.coinsReward,
					rewardClaimed: response.rewardClaimed,
				}
			} catch (error) {
				error.value = t('error')
			}
		}
	},
	{ immediate: true }
)
</script>
