<template>
	<BaseDialog
		v-model="dialogStore.dialogs.settings.active"
		:persistent="isInitial"
		name="settings"
	>
		<template v-slot:text>
			<div v-if="Object.keys(errors).length > 0">
				<v-alert color="red-darken-3" class="mb-4 px-4" variant="tonal">
					<div v-for="error in errors">• {{ error }}</div>
				</v-alert>
			</div>

			<div v-if="!user.gender">
				<v-alert
					color="teal-lighten-2 text-subtitle-2"
					class="mb-4 px-4 mt-4 rounded-lg"
					variant="tonal"
				>
					{{ $t('settings.initial') }}
				</v-alert>
				<h4 class="mb-2 d-flex justify-center">
					{{ $t('settings.yourGender') }}:
				</h4>
				<div class="mt-2 mb-4 ms-n2 d-flex justify-center">
					<v-btn
						stacked
						variant="text"
						size="small"
						color="pink-lighten-1"
						class="m-2 rounded-lg"
						:min-width="100"
						:active="settingsData.gender === 1"
						@click="handleGenderClick(1)"
					>
						<v-icon :icon="mdiGenderFemale" class="mb-1"></v-icon>
						<span>{{ $t('settings.yourGenderFemale') }}</span>
					</v-btn>
					<v-btn
						stacked
						variant="text"
						size="small"
						color="blue-accent-2"
						class="m-2 rounded-lg"
						:min-width="100"
						:active="settingsData.gender === 2"
						@click="handleGenderClick(2)"
					>
						<v-icon :icon="mdiGenderMale" class="mb-1"></v-icon>
						<span>{{ $t('settings.yourGenderMale') }}</span>
					</v-btn>
				</div>
			</div>

			<h4 class="mb-2 d-flex justify-center">
				{{ $t('settings.votePhotos') }}:
			</h4>
			<div class="mt-2 ms-n2 d-flex justify-center flex-wrap">
				<v-btn
					:stacked="isInitial"
					variant="text"
					:size="isInitial ? 'small' : 'default'"
					color="pink-lighten-1"
					class="ma-1 rounded-lg"
					:min-width="120"
					:active="settingsData.voteGender === 1"
					@click="handleVoteGenderClick(1)"
				>
					<v-icon
						:icon="mdiGenderFemale"
						:class="isInitial ? 'mb-1' : null"
					></v-icon>
					<span>{{ $t('settings.votePhotosFemale') }}</span>
				</v-btn>
				<v-btn
					:stacked="isInitial"
					variant="text"
					:size="isInitial ? 'small' : 'default'"
					color="blue-accent-2"
					class="ma-1 rounded-lg"
					:min-width="120"
					:active="settingsData.voteGender === 2"
					@click="handleVoteGenderClick(2)"
				>
					<v-icon
						:icon="mdiGenderMale"
						:class="isInitial ? 'mb-1' : null"
					></v-icon>
					<span>{{ $t('settings.votePhotosMale') }}</span>
				</v-btn>
				<v-btn
					:stacked="isInitial"
					variant="text"
					:size="isInitial ? 'small' : 'default'"
					color="purple-lighten-1"
					class="ma-1 rounded-lg"
					:min-width="120"
					:active="settingsData.voteGender === 3"
					@click="handleVoteGenderClick(3)"
				>
					<v-icon
						:icon="mdiGenderMaleFemale"
						:class="isInitial ? 'mb-1' : null"
					></v-icon>
					<span>{{ $t('settings.votePhotosBoth') }}</span>
				</v-btn>
			</div>

			<div v-if="!isInitial">
				<h4 class="mb-2 mt-4 d-flex justify-center">
					{{ $t('settings.language') }}:
				</h4>
				<div class="mt-2 ms-n2 d-flex justify-center flex-wrap">
					<v-btn
						v-for="availableLocale in config.i18n.availableLocales"
						:key="availableLocale"
						variant="text"
						:active="availableLocale == locale"
						class="ma-1 rounded-lg"
						:min-width="120"
						@click="selectLang(availableLocale)"
					>
						<img
							height="12"
							class="me-1"
							:alt="locale"
							:src="`/images/flags/${availableLocale}.svg`"
						/>
						{{ $t(`locales.${availableLocale}`) }}
					</v-btn>
				</div>

				<h4 class="mb-2 mt-6 d-flex justify-center">
					{{ $t('settings.theme') }}:
				</h4>
				<div class="mt-2 ms-n2 d-flex justify-center flex-wrap">
					<v-btn
						variant="text"
						:active="theme.global.name.value == 'light'"
						class="ma-1 rounded-lg"
						:min-width="120"
						@click="selectTheme('light')"
					>
						<v-icon :icon="mdiLightbulbOn10" size="12" class="me-1"></v-icon>
						<span>{{ $t('settings.themeLight') }}</span>
					</v-btn>
					<v-btn
						variant="text"
						:active="theme.global.name.value == 'dark'"
						class="ma-1 rounded-lg"
						:min-width="120"
						@click="selectTheme('dark')"
					>
						<v-icon :icon="mdiMoonWaningCrescent" size="12"></v-icon>
						<span>{{ $t('settings.themeDark') }}</span>
					</v-btn>
					<v-btn
						v-if="webApp?.initData"
						variant="text"
						:active="theme.global.name.value == 'tg'"
						class="ma-1 rounded-lg"
						:min-width="120"
						@click="selectTheme('tg')"
					>
						<img class="me-1" height="15" src="/images/telegram.webp" />
						<span>{{ $t('settings.themeTg') }}</span>
					</v-btn>
				</div>
			</div>
		</template>
		<template v-slot:actions>
			<v-btn
				variant="elevated"
				:color="isInitial ? 'green-darken-4' : null"
				class="d-block mx-auto rounded-lg"
				width="180"
				@click="isInitial ? saveInitial() : dialogStore.closeDialog('settings')"
				:loading="isLoading"
				:disabled="isInitial && !formReady"
			>
				<div class="d-flex">
					<v-icon :icon="isInitial ? mdiCheck : null" class="me-1"></v-icon>
					<span>{{ $t(isInitial ? 'save' : 'close') }}</span>
				</div>
			</v-btn>
		</template>
	</BaseDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
	mdiCheck,
	mdiGenderFemale,
	mdiGenderMale,
	mdiGenderMaleFemale,
	mdiLightbulbOn10,
	mdiMoonWaningCrescent,
} from '@mdi/js'
import { useDialogStore } from '../../stores/dialogStore'
import { useUserStore } from '../../stores/userStore'
import { useApi } from '../../composables/useApi'
import BaseDialog from '../BaseDialog.vue'
import { useI18n } from 'vue-i18n'
import { useTheme } from 'vuetify'
import { useWebApp } from '../../plugins/webApp'
import config from '../../config'
import {
	changeLanguage,
	type LanguageType,
} from '../../composables/useLanguage'

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const dialogStore = useDialogStore()
const user = computed(() => userStore.user)

const settingsData = ref<SettingsData>({
	gender: null,
	voteGender: null,
})

const isInitial = computed(
	() =>
		user.value &&
		(user.value.gender === null || user.value.vote_gender === null)
)

const formReady = computed(() =>
	Object.values(settingsData.value).every((x) => x !== null)
)

const errors = ref<string[]>([])
const isLoading = ref(false)

const { locale } = useI18n({ useScope: 'global' })
const theme = useTheme()
const webApp = useWebApp()

async function saveGenderSettings() {
	errors.value = []
	isLoading.value = true

	try {
		const response = await useApi.user.saveSettings(settingsData.value)

		if (response.errors) {
			errors.value = response.errors
		} else {
			if (user.value) {
				user.value.gender = settingsData.value.gender
				user.value.vote_gender = settingsData.value.voteGender
			}

			if (route.name === 'vote') {
				router.push({ path: '/vote', force: true })
			}
		}
	} catch (error) {
		errors.value = [error.message]
	} finally {
		isLoading.value = false
	}
}

const handleGenderClick = (gender: number) => {
	settingsData.value.gender = gender
	if (!isInitial.value) {
		saveGenderSettings()
	}
}

const handleVoteGenderClick = (voteGender: number) => {
	settingsData.value.voteGender = voteGender
	if (!isInitial.value) {
		saveGenderSettings()
	}
}

watch(user, (newUser) => {
	if (newUser) {
		settingsData.value.gender = newUser.gender
		settingsData.value.voteGender = newUser.vote_gender
	} else {
		settingsData.value.gender = null
		settingsData.value.voteGender = null
	}
})

watch(
	() => dialogStore.dialogs.settings.active,
	(newVal) => {
		if (newVal === true) {
			settingsData.value.gender = user.value ? user.value.gender : null
			settingsData.value.voteGender = user.value ? user.value.vote_gender : null
			errors.value = []
		}
	}
)

watch(
	isInitial,
	(val) => {
		if (val === true) dialogStore.openDialog('settings')
	},
	{ immediate: true }
)

async function selectLang(lang: LanguageType) {
	await changeLanguage(lang)
}

function selectTheme(val: string) {
	theme.global.name.value = val
	localStorage.setItem('theme', val)
}

async function saveInitial() {
	await saveGenderSettings()
	if (errors.value.length === 0) {
		dialogStore.closeDialog('settings')
	}
}
</script>

<style scoped></style>
